import {EventModel} from 'event/models/Event'

export const DEFAULT_TICKET_PRICE = 10.0
export const DEFAULT_TICKET_TAX = 30.0
export const MIN_TICKET_TAX = 3

export function getTicketPrice(event: EventModel) {
  return event.price
}

export function getTicketPriceWithoutTax(price: number) {
  const calculatedTax = (price / 100) * DEFAULT_TICKET_TAX
  const tax = calculatedTax <= MIN_TICKET_TAX ? MIN_TICKET_TAX : calculatedTax
  return Math.floor((price - tax) * 100) / 100
}
