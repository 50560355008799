import React from 'react'
import {Login} from 'attendee/Auth/Login'
import {Navigate, Route, Routes} from 'react-router-dom'
import {routes} from 'routes/routes'
import {Index} from 'attendee'
import {Register} from 'attendee/Auth/Register'
import AttendeeEventsListProvider from 'attendee/providers/AttendeeEventsListProvider'
import {Billing} from 'attendee/Billing'
import {Tickets} from 'attendee/Tickets'
import {AuthReqired} from 'routes/AuthReqired'
import {Profile} from 'attendee/Auth/Profile'
import {PasswordRecover} from 'attendee/Auth/PasswordRecover'
import {GoogleCallback} from 'attendee/Auth/GoogleCallback'
import {Info} from 'attendee/Info'
import {Search} from 'attendee/Search'
import {Help} from 'attendee/Help'

export function AttendeeRoutes() {
  return (
    <>
      <Routes>
        <Route path={routes.login} element={<Login />} />

        <Route path={routes.register} element={<Register />} />

        <Route path={routes.auth.google} element={<GoogleCallback />} />

        <Route path={routes.passwordRecover} element={<PasswordRecover />} />

        <Route path={routes.profile} element={<Profile />} />

        <Route path={routes.billing} element={<Billing />} />

        <Route
          path={routes.tickets}
          element={
            <AuthReqired>
              <Tickets />
            </AuthReqired>
          }
        />

        <Route path={routes.info} element={<Info />} />

        <Route path={routes.help} element={<Help />} />

        <Route
          path={routes.search}
          element={
            <AttendeeEventsListProvider>
              <Search />
            </AttendeeEventsListProvider>
          }
        />

        <Route
          path={routes.index}
          element={
            <AttendeeEventsListProvider>
              <Index />
            </AttendeeEventsListProvider>
          }
        />

        <Route path="*" element={<Navigate to={routes.index} replace />} />
      </Routes>
    </>
  )
}
