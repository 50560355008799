import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import React from 'react'
import {GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google'

export function GoogleOneTab() {
  const {oneTabGoogleLogin, user} = useAuth()

  if (user) return null

  return (
    <GoogleOAuthProvider clientId="40678166620-5dnfntq63o4da7cm91hftm85q25192oa.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={oneTabGoogleLogin}
        auto_select={true}
        onError={() => {
          console.log('Login Failed')
        }}
        useOneTap
      />
    </GoogleOAuthProvider>
  )
}
