import React from 'react'
import {Link} from 'react-router-dom'
import footer1 from 'assets/img/shape/footer/footer-1.png'
import footer2 from 'assets/img/shape/footer/footer-2.png'
import footer3 from 'assets/img/shape/footer/footer-3.png'
import footer4 from 'assets/img/shape/footer/footer-4.png'
import {routes} from 'routes/routes'

export function Footer() {
  return (
    <>
      <footer>
        <section className="footer__area footer-bg fix p-relative pt-145">
          <div className="footer__shape">
            <img
              style={{width: 'auto', height: 'auto'}}
              className="footer__shape-1"
              src={footer1}
              alt=" not found"
            />
            <img
              style={{width: 'auto', height: 'auto'}}
              className="footer__shape-2"
              src={footer2}
              alt=" not found"
            />
            <img
              style={{width: 'auto', height: 'auto'}}
              className="footer__shape-3"
              src={footer3}
              alt=" not found"
            />
            <img
              style={{width: 'auto', height: 'auto'}}
              className="footer__shape-4"
              src={footer4}
              alt=" not found"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                <div className="footer__widget-1 mb-70">
                  <div className="footer__logo mb-30">
                    <img
                      src={process.env.PUBLIC_URL + '/logo.svg'}
                      style={{maxWidth: '300px', height: 'auto'}}
                      alt="logo"
                    />
                  </div>
                  <div className="footer__content">
                    <p>Онлайн събития за малкия и среден бизнес.</p>

                    <div className={'footer__social'}>
                      <a
                        href="https://www.facebook.com/eventatv"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                <div className="footer__widget-2 mb-70">
                  <div className="footer__widget-title">
                    <h4>Полезни линкове</h4>
                  </div>
                  <div className="footer__link">
                    <ul>
                      <li>
                        <Link to={routes.root}>Събития</Link>
                      </li>
                      <li>
                        <Link to={routes.tickets}>Билети</Link>
                      </li>
                      <li>
                        <Link to={routes.info}>Какво е Еventa.tv</Link>
                      </li>
                      <li>
                        <Link to={routes.help}>Помощ</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                <div className="footer__widget-3 mb-70">
                  <div className="footer__widget-title">
                    <h4>Организация</h4>
                  </div>
                  <div className="footer__link">
                    <ul>
                      <li>
                        <Link to={routes.organization.login}>Вход</Link>
                      </li>
                      <li>
                        <Link to={routes.organization.register}>
                          Регистрация
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="footer__copyright text-center">
                  <p>
                    Всички права запазени{' '}
                    <a target="_blank" href="https://atomicagroup.com/">
                      Atomica Group
                    </a>{' '}
                    - 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  )
}
