import {eventRoutes} from 'event/EventRoutes'
import {getEventImage, updateEventImagesAgregate} from 'event/models/Event'
import {useFormik} from 'formik'
import EventPage from 'organization/Event/EventPage'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import {SaveEventData} from 'organization/Event/Settings/SaveEvent'
import {Image, ImageSelector} from 'organization/ImageSelector'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import styled from 'styled-components'
import TextEditor from 'utils/ui/TextEditor'

export const Vision = () => {
  const {event, updateEvent, loading} = useOrganizationEvent()

  const {handleSubmit, handleBlur, handleChange, values, setValues} = useFormik(
    {
      initialValues: {
        short_description: event?.short_description,
        description: event?.description,
        video: event?.video,
      },
      onSubmit: async (values: SaveEventData) => {
        const result = await updateEvent(values)

        if (result === true) {
          toast.success('Запазено')
        }
      },
    },
  )

  const handleDescriptionChange = (value: string) => {
    setValues({
      ...values,
      description: value,
    })
  }

  const changeEventImage = (type: number, updatedImage: Image | null) => {
    setValues({
      ...values,
      images: updateEventImagesAgregate(values.images, type, updatedImage),
    })
  }

  if (!event) return null

  const routes = eventRoutes(event)

  return (
    <EventPage>
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Визия" />

          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper">
                <PreviewHolder className="pricing__item">
                  <PreviewText>
                    {event.is_public && (
                      <>
                        {' '}
                        <i className="flaticon-warning-1"></i> Страницата е
                        публично достъпна.
                      </>
                    )}
                    {!event.is_public && (
                      <>
                        {' '}
                        <i className="flaticon-warning-1"></i> Страницата е
                        достъпна само за Вас.
                        <br />В настройки можете да активирате "Публичен достъп"
                      </>
                    )}
                  </PreviewText>
                  <Link
                    to={routes.view + '?preview=1'}
                    target="_blank"
                    className="input__btn"
                    style={{width: 300}}
                  >
                    Преглед на страницата
                  </Link>
                </PreviewHolder>

                {/* create event form  */}
                <form onSubmit={handleSubmit}>
                  <div className="create__event-main pt-25">
                    <div className="event__left-box">
                      <div className="create__input-wrapper">
                        <div className="event__input mb-15">
                          <label className="input__field-text">
                            Кратко Описание
                          </label>

                          <textarea
                            name="short_description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {values.short_description}
                          </textarea>
                        </div>

                        <div className="event__input mb-15">
                          <label className="input__field-text">Описание</label>

                          <TextEditor
                            value={values.description ?? ''}
                            onChange={handleDescriptionChange}
                            fullModules
                          />
                        </div>

                        <div className="singel__input-field  mb-15">
                          <label className="input__field-text">
                            Youtube Видео
                          </label>

                          <input
                            placeholder="https://www.youtube.com/watch?v=kC5949RveFc"
                            type="text"
                            name="video"
                            value={values.video}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div>
                          <label className="input__field-text">Език</label>
                          <div className="contact__select">
                            <select>
                              <option defaultValue="0">Български</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="event__right-box">
                      <div className="event__input mb-15">
                        <label className="input__field-text">
                          {' '}
                          Основно Изображение - <i>700 x 400</i>{' '}
                        </label>
                        <ImageSelector
                          onSelect={(image: Image | null) =>
                            changeEventImage(1, image)
                          }
                          preview={true}
                          selected={getEventImage(event, 1)}
                        />
                      </div>

                      <div className="event__input mb-15">
                        <label className="input__field-text">
                          {' '}
                          Изображение за видео фон - <i>600 x 350</i>{' '}
                        </label>
                        <ImageSelector
                          onSelect={(image: Image | null) =>
                            changeEventImage(2, image)
                          }
                          preview={true}
                          selected={getEventImage(event, 2)}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className={
                      loading
                        ? 'disableBtnStyle w-100 mt-30'
                        : 'input__btn w-100 mt-30'
                    }
                  >
                    Запазване
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

const PreviewHolder = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 40px;
`

const PreviewText = styled.div`
  color: #333333;
  & > i {
    margin-inline-end: 10px;
    color: var(--clr-theme-1);
  }
`
function setBtnDisable(arg0: boolean) {
  throw new Error('Function not implemented.')
}
