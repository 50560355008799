import {EventModel} from 'event/models/Event'
import {SaveEventData} from 'organization/Event/Settings/SaveEvent'
import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useRouteOrganizationEventId} from 'utils/lib/url'

export interface OrganizationEventContextProps {
  event: EventModel | null
  updateEventTemplate: (eventTemplate: EventModel['template']) => void
  updateEvent: (data: SaveEventData) => Promise<string | boolean | undefined>
  makeEventPublic: () => Promise<string | boolean | undefined>
  endEvent: () => Promise<string | boolean | undefined>
  loading: boolean
}

export const OrganizationEventContext = React.createContext<
  OrganizationEventContextProps | undefined
>(undefined)

export default function OrganizationEventProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [event, setEvent] = useState<EventModel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [initLoading, setInitLoading] = useState<boolean>(true)
  const eventId = useRouteOrganizationEventId()

  useEffect(() => {
    setInitLoading(true)
    get()
  }, [])

  const get = async () => {
    setLoading(true)
    await apiClient
      .get(`api/organization/event/${eventId}`)
      .then((response) => {
        setEvent(response)
      })
      .finally(() => {
        setLoading(false)
        setInitLoading(false)
      })
  }

  const updateEventTemplate = async (eventTemplate: EventModel['template']) => {
    if (loading) return

    setLoading(true)
    await apiClient
      .post(`api/event/${eventId}/template`, {
        template: JSON.stringify(eventTemplate),
      })
      .finally(() => setLoading(false))
  }

  const updateEvent = async (data: SaveEventData) => {
    if (loading) return
    setLoading(true)
    const result = await apiClient
      .post(`api/event/${eventId}/update`, data)
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })
      .finally(() => setLoading(false))

    if (result) return true

    return false
  }

  const makeEventPublic = async () => {
    if (loading) return
    setLoading(true)
    const result = await apiClient
      .post(`api/event/${eventId}/make-public`)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })
      .finally(() => setLoading(false))

    return result
  }

  const endEvent = async () => {
    if (loading) return
    setLoading(true)
    const result = await apiClient
      .post(`api/event/${eventId}/end`)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })
      .finally(() => setLoading(false))

    return result
  }

  return (
    <OrganizationEventContext.Provider
      value={{
        event,
        updateEventTemplate,
        updateEvent,
        makeEventPublic,
        endEvent,
        loading,
      }}
    >
      {initLoading && <FullPageLoader />}
      {!initLoading && props.children}
    </OrganizationEventContext.Provider>
  )
}

export function useOrganizationEvent() {
  const context = React.useContext(OrganizationEventContext)
  if (context === undefined) {
    throw new Error(
      'useOrganizationEvent must be used within OrganizationEventProvider',
    )
  }
  return context
}
