import React from 'react'
import {routes} from 'routes/routes'
import Shape1 from 'assets/img/shape/join-shape-1.png'
import Shape2 from 'assets/img/shape/join-shape-2.png'
import hometalker from 'assets/custom_images/hometalker.png'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

export function WhyOnlineEvent() {
  return (
    <section
      className="join__area p-relative  z-index-1 fix"
      style={{backgroundImage: `url(${hometalker})`}}
    >
      <div className="join__all-shape p-relative">
        <img
          className="join__shape-1"
          src={Shape1}
          style={{width: 'auto', height: 'auto'}}
          alt="image not found"
        />
        <img
          className="join__shape-2"
          src={Shape2}
          style={{width: 'auto', height: 'auto'}}
          alt="image not found"
        />
        <div className="container">
          <div className="join__main-wrapper pt-150 pb-90">
            <div className="row align-items-sm-start">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="join__features-wrapper mb-60">
                  <div className="join__features-grid">
                    <div className="join__features-item text-center">
                      <div className="join__features-icon">
                        <span>
                          <i className="flaticon-network"></i>
                        </span>
                      </div>
                      <h3 className="join__features-title"> Изградена мрежа</h3>
                    </div>
                    <div className="join__features-item text-center">
                      <div className="join__features-icon">
                        <span>
                          {' '}
                          <i className="flaticon-dashboard-2"></i>
                        </span>
                      </div>
                      <h3 className="join__features-title">Конструктор</h3>
                    </div>
                    <div className="join__features-item text-center">
                      <div className="join__features-icon">
                        <span>
                          <i className="flaticon-trophy-1"></i>
                        </span>
                      </div>
                      <h3 className="join__features-title">Лесна продажба</h3>
                    </div>
                    <div className="join__features-item text-center">
                      <div className="join__features-icon">
                        <span>
                          {' '}
                          <i className="flaticon-confetti"></i>
                        </span>
                      </div>
                      <h3 className="join__features-title">Много забава</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="join__content-wrapper mb-60">
                  <div className="section__title-wrapper is-white mb-40">
                    <span className="section__back-title">ОС</span>
                    <span className="section__subtitle">Предимства</span>
                    <h2 className="section__title">
                      Защо{' '}
                      <span className="text__highlight"> Онлайн събитие</span> ?
                    </h2>
                  </div>
                  <div className="join__content mb-70">
                    <p>
                      Създаването на онлайн събитие има множество предимства,
                      които го правят изключително привлекателно в съвременния
                      дигитален свят.
                      <br /> <br />
                      Ето няколко ключови причини защо трябва да обмислите
                      организирането на такова събитие при нас:
                    </p>
                    <StyledUL className="mt-30">
                      <li>
                        <p>
                          <span>1</span> Лесна продажба на билети
                        </p>
                        <p>
                          <span>2</span> Намалени разходи
                        </p>
                        <p>
                          <span>3</span> Гъвкавост и иновации
                        </p>
                        <p>
                          <span>4</span> Екологичност
                        </p>
                        <p>
                          <span>5</span> Лесно измерване на успеха
                        </p>
                        <p>
                          <span>6</span> Глобален обхват
                        </p>
                      </li>
                    </StyledUL>
                  </div>
                  <Link
                    to={routes.organization.register}
                    className="fill__btn fill_btn_new"
                  >
                    Регистрация на организация сега
                    <i className="fa-regular fa-angle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const StyledUL = styled.ul`
  & > li {
    display: flex;
    flex-flow: wrap;
    gap: 20px 0;
  }

  & > li > p {
    width: 50%;
  }
  & > li > p > span {
    background: var(--clr-theme-1);
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-weight: bold;
    font-size: 1.3rem;
  }

  @media (max-width: 575px) {
    & > li > p {
      width: 100%;
    }
  }
`
