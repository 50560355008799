import React from 'react'
import {Link} from 'react-router-dom'
import contactsImage from 'images/contacts.png'
import EventPage from 'organization/Event/EventPage'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import styled from 'styled-components'
import {FAQ} from 'organization/Event/Help/FAQ'

export function Help() {
  return (
    <EventPage>
      <div className="row pl-30 pb-30 pr-30">
        <BreadcrumMenu title="Помощ" />

        <div className="col-xxl-12">
          <div className="create__event-area">
            <div className="body__card-wrapper">
              {/* create event form  */}

              <Title className="mb-35">Често задавани въпроси</Title>

              <FAQ />

              <Title className="mb-35 mt-80">Връзка с нас</Title>

              <div className="row g-4">
                <div className=" col-12 col-lg-6">
                  <img src={contactsImage} width="100%" alt="" />
                </div>

                <div className="col-12 col-lg-6">
                  <ul className="contact_info_list style_2 ps-lg-4 unordered_list_block">
                    <li>
                      <strong>Телефон:</strong>
                      Скрит
                    </li>
                    <li>
                      <strong>Имейл:</strong>
                      <Link to="mailto:support@eventa.tv" target="_blank">
                        support@eventa.tv
                      </Link>
                    </li>
                    <li>
                      <strong>Работно време:</strong>
                      <span>09:00 - 15:00, от Понеделник до Петък</span>
                    </li>
                    <li>
                      <strong>Последвай ни:</strong>
                      <div className="social_icon unordered_list">
                        <div className={'footer__social'}>
                          <a
                            href="https://www.facebook.com/eventatv"
                            target="_blank"
                          >
                            <i className="fa-brands fa-facebook"></i>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EventPage>
  )
}

const Title = styled.div`
  color: var(--clr-text-secondary);
  font-weight: bold;
  font-size: 2rem;
`
