import {EventImage} from 'event/models/EventImages'
import {useOrganizationBilling} from 'organization/Billing/Providers/OrganizationBillingProvider'
import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import styled from 'styled-components'

export type Image = {
  url: string
  id: number
}

export function publicImageUrl(url?: string | null) {
  if (!url) return

  if (url.startsWith('example-templates/')) {
    console.log(process.env.REACT_APP_URL + url)
    return process.env.REACT_APP_URL + url
  }

  return process.env.REACT_APP_IMAGES_URL + url
}

export function ImageSelector(props: {
  onSelect: (image: Image | null) => void
  preview?: boolean
  selected?: EventImage | null
}) {
  const {setImagesModalOpen, setOnImageSelect} = useOrganizationBilling()
  const [selectedImage, setSelectedImage] = useState<Image | null | 'clear'>(
    null,
  )

  const handleOnClick = () => {
    setImagesModalOpen(true)
    setOnImageSelect((image: Image) => {
      setSelectedImage(image)
      props.onSelect(image)
      setImagesModalOpen(false)
    })
  }

  const handleOnClear = () => {
    setSelectedImage('clear')
    props.onSelect(null)
  }

  return (
    <>
      {props.preview && (
        <Preview
          onClick={handleOnClick}
          selectedImage={selectedImage}
          selected={props.selected}
          onClear={handleOnClear}
        />
      )}
      {!props.preview && (
        <Normal onClick={handleOnClick} onClear={handleOnClear} />
      )}
    </>
  )
}

function Normal(props: {onClick: () => void; onClear: () => void}) {
  return (
    <>
      <Button
        style={{background: '#ffffff', color: '#000000'}}
        variant="primary"
        onClick={props.onClick}
      >
        Избери изображение
      </Button>

      <NormalClear onClick={props.onClear}>
        <i className="fa-regular fa-xmark"></i>
      </NormalClear>
    </>
  )
}

function Preview(props: {
  selectedImage: Image | null | 'clear'
  onClick: () => void
  selected?: EventImage | null
  onClear: () => void
}) {
  const getSelectedImage = () => {
    if (props.selectedImage === 'clear') {
      return null
    }

    if (props.selectedImage && props.selectedImage.url) {
      return props.selectedImage
    }

    if (props.selected) {
      return props.selected.image
    }

    return null
  }

  const selected = getSelectedImage()

  if (!selected) {
    return (
      <div
        className="box__input"
        style={{
          borderRadius: '6px',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          height: '200px',
        }}
      >
        <label
          onClick={props.onClick}
          className="input__field-text"
          style={{
            height: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>
            <i className="fa-regular fa-plus"></i>
          </span>
          <span>Избери изображение</span>
        </label>
      </div>
    )
  }

  return (
    <div
      className="box__input"
      style={{
        borderRadius: '6px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        height: '200px',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(${publicImageUrl(selected.url)})`,
        position: 'relative',
      }}
    >
      <label
        className="input__field-text"
        style={{
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          className="update__thumb-close"
          onClick={props.onClear}
          style={{
            background: 'var(--clr-theme-1)',
            color: '#fff',
            lineHeight: '30px',
            fontWeight: 'bold',
          }}
        >
          <i className="fa-regular fa-xmark"></i>
        </span>
        <span
          onClick={props.onClick}
          className="input__btn"
          style={{
            borderRadius: '6px',
            color: '#fff',
            display: 'flex',
          }}
        >
          Смени изображение
        </span>
      </label>
    </div>
  )
}

const NormalClear = styled.div`
  background: var(--clr-gradient-14);
  margin-left: 10px;
  display: inline-flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  vertical-align: middle;
  float: right;
  cursor: pointer;
`
