import {FAQItem} from 'attendee/Help/FAQ'
import React from 'react'
export function FAQ() {
  return (
    <div className="accordion" id="accordionFAQ">
      <FAQItem
        id={'q1'}
        open={true}
        question={'Какви са стъпките за създаване на събитие'}
        answer={
          <>
            <b>1.</b> Попълнете информацията за вашето събитие в меню "Визия"{' '}
            <br />
            <b>2.</b> Изберете цена, име и характеристики на билета в меню
            "Билети"
            <br />
            <b>3.</b> Изградете вашата основна страница в меню "Дизайн"
            <br />
            <b>4.</b> Стартирайте продажбата на билети като направите събитието
            публично от меню "Настройки"
            <br />
            <b>5.</b> На съответната дата стартирайте събитието от меню
            "Управление"
            <br />
          </>
        }
      />
    </div>
  )
}
