import React from 'react'
import {Link} from 'react-router-dom'
import small_logo from 'assets/img/logo/logo-small.svg'
import {routes} from 'routes/routes'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'

export const Menu = () => {
  return (
    <>
      <div className="logo-details">
        <span>
          <Link to={routes.root}>
            <img
              style={{width: '100%', height: '100%', maxHeight: '50px'}}
              className="logo__white"
              src={small_logo}
              alt="logo not found"
            />
          </Link>
        </span>
        <span>
          <Link to={routes.root}>
            <img
              style={{width: '180px', height: 'auto'}}
              className="log__smnall"
              src={process.env.PUBLIC_URL + '/logo-dark.svg'}
              alt="logo not found"
            />
          </Link>
        </span>
      </div>
      <div className="sidebar__inner simple-bar">
        <div className="dlabnav">
          <ul className="metismenu">
            <li>
              <Link to={routes.root}>
                <i>
                  <DateRangeIcon />
                </i>
                <span className="nav-text">Събития</span>
              </Link>
            </li>
            <li>
              <Link to={routes.tickets}>
                <i>
                  <ConfirmationNumberIcon />
                </i>
                <span className="nav-text">Билети</span>
              </Link>
            </li>
            <li>
              <Link to={routes.info}>
                <i>
                  <TipsAndUpdatesIcon />
                </i>
                <span className="nav-text">Какво е Eventa.tv</span>
              </Link>
            </li>
            <li>
              <Link to={routes.help}>
                <i>
                  <LiveHelpIcon />
                </i>
                <span className="nav-text">Помощ</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
