import React, {useEffect, useState} from 'react'
import {useRouteEventId} from 'utils/lib/url'
import {attendeeApiClient} from 'attendee/apiClient'
import {EventModel} from 'event/models/Event'
import {BasicLoader} from 'attendee/theme/BasicLoader'

export interface EventPreviewContextProps {
  event: EventModel | null
}

export const EventPreviewContext = React.createContext<
  EventPreviewContextProps | undefined
>(undefined)

export default function EventPreviewProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = attendeeApiClient()
  const [event, setEvent] = useState<EventModel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const EventPreviewId = useRouteEventId()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(
        `api/event/${EventPreviewId}/preview`,
      )
      setEvent(response)
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
    }
  }
  return (
    <EventPreviewContext.Provider
      value={{
        event,
      }}
    >
      {loading && <BasicLoader />}
      {!loading && props.children}
    </EventPreviewContext.Provider>
  )
}

export function useEventPreview() {
  const context = React.useContext(EventPreviewContext)
  if (context === undefined) {
    throw new Error('useEventPreview must be used within EventPreviewProvider')
  }
  return context
}
