import {Footer} from 'attendee/theme/Footer'
import {Header} from 'attendee/theme/Header'
import Modals from 'attendee/theme/Modals'
import Sidebar from 'attendee/theme/Sidebar'
import React from 'react'
import {GoogleOneTab} from 'attendee/Auth/GoogleOneTab'

export default function Page(props: {
  children: React.ReactElement
  disableModals?: boolean
  disableFooter?: boolean
}) {
  return (
    <main className={`body-area bd-theme-light `}>
      <GoogleOneTab />

      {props.disableModals ?? <Modals />}
      <div className="page__full-wrapper">
        <Sidebar />
        <div className="page__body-wrapper">
          <Header />
          <div className="app__slide-wrapper">{props.children}</div>
          {props.disableFooter ?? <Footer />}
        </div>
      </div>
    </main>
  )
}
